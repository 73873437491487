import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../../templates/layout-standard"
import Seo from "../../../components/seo"
import SideBar from '../../../components/sidebar';
import SixtyFivePlusNav from '../../../components/nav/SixtyFivePlus';
import UpNextCardNonStrapi from '../../../components/ui/UpNextCardNonStrapi';
import LearnMore from '../../../components/learnmore';
import SpeakWithAdvisor from '../../../components/ui/SpeakWithAdvisor';

const SixtyFivePlusStart = ({ location }) => {

	return (
		<Layout location={location} heading1="65+ (first time enrolling)" heading2="Understanding your Medicare Options.">
			
			<Seo title="Where To Start | 65+ | Medicare Basics" meta={[{description: 'If you are 65 or older and have been covered under your employer\'s health plan, your spouse\'s plan, or a Marketplace plan, you can still enroll in Medicare up to two months after your current coverage ends.'}]} bodyclass="medicare-basics section2 subpage" />
			<SixtyFivePlusNav />
				
			<div class="constrained">
				<div class="content-constrained">
					<div class="flexwrap_subpage">

						<main>
							<h3>Where to Start for Medicare</h3>
					
							<p>If you are 65 or older and have been covered under your employer&rsquo;s health plan, your spouse&rsquo;s plan, or a Marketplace plan, you can still enroll in Medicare up to two months after your current coverage ends. <a href="https://www.medicare.gov/basics/get-started-with-medicare/sign-up/when-can-i-sign-up-for-medicare" class="external">Get more details</a>.</p>

							<p>Use the links above to explore your Medicare options if you are enrolling past 65.</p>

							<p><b>Need help picking a plan?</b> Get a <a href="https://bestplanforme.mvphealthcare.com/?sourcesite=uvm">personalized plan recommendation</a>.</p>

							<p>Not yet 65? <Link to="../turning-65/where-to-start/">Here&rsquo;s what you should be doing</Link>.</p>

							<UpNextCardNonStrapi to="/medicare-basics/65-plus/learn-how-medicare-works/">Learn How Medicare Works</UpNextCardNonStrapi>
							<LearnMore />
							<SpeakWithAdvisor />
						</main>
						
						<SideBar location={location} />
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default SixtyFivePlusStart
